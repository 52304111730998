<!--
 * @Author: your name
 * @Date: 2020-12-24 16:52:00
 * @LastEditTime: 2021-06-01 17:52:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \two\src\views\home\index.vue
-->
<template>
  <div class="allmsgclass2">
    <div class="topmsg">
      <div class="setup">
        <div></div>
        <div class="setupclass"></div>
      </div>
      <div class="mybodyMsg">
        <div class="imageContact">
          <div class="themsg">
            <div class="imgdiv">
              <img class="touimg" src="../../assets/userss.png" alt="个人头像" />
            </div>
            <div class="contact">
              <p id="pclass">
                {{
                  userInfo.company_name == "" ||
                  userInfo.company_name == undefined
                    ? userInfo.account
                    : userInfo.company_name
                }}
              </p>
              <div class="renimgclass">
                <p>萝卜猎手业务合伙人</p>
                <img id="imgid" v-if="okren == true" src="../../assets/verified.png" />
                <img v-else src="../../assets/notcertified.png" />
              </div>
            </div>
          </div>
          <div class="qrclss">
            <!-- <img src="../../assets/qrimg.png" /> -->
          </div>
        </div>
        <!-- <div class="daoclass">您已经邀请了 1 个渠道</div> -->
      </div>
    </div>

    <div
      @click="$router.push('systeminformation')"
      class="noticeclass topmsgcalssthe"
    >
      <div>
        <img class="img1" src="../../assets/notice.png" />
      </div>
      <div v-if="top1msg.content != undefined">
        {{ top1msg.source }}：{{ top1msg.content }} {{ top1msg.title }}
      </div>
      <div v-else>暂无最新消息</div>
    </div>
    <!-- <div @click="$router.push('/problem')" class="banner"> -->
    <div @click="$router.push('/invitemsg')" class="banner">
      <van-swipe :autoplay="5000" indicator-color="#fff">
        <van-swipe-item v-for="(image, index) in SwiperImg" :key="index">
          <img class="imgclass" :src="image" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="actclass">
      <van-tabs v-model="active" @change="tabchange">
        <van-tab title="最近邀请">
          <div v-for="(item,index) in sub" v-bind:key="index" class="zccla">
            <div class="zcclaceclass">{{ item.company_name?item.company_name:"该企业未认证" }}</div>
            <div class="timeandmsgclass">
              <img src="../../assets/time.png" />
              <div>{{ item.add_time }}</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="最近收益">
          <div v-for="(item,index) in recharge" v-bind:key="index" class="zccla">
            <div class="zcclaceclass">{{ item.company_name }}</div>
            <div class="montimeclass">
              <div class="withdrawclassthe">
                <div>获得收益：<span>{{item.order_amount}}</span>元</div>
              </div>
              <div class="timeandmsgclass">
                <img src="../../assets/time.png" />
                <div>{{ item.add_time }}</div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab disabled title=""></van-tab>
        <van-tab title="更多">
          <template #title><div class="moremsgclass">更多<van-icon name="arrow" /></div></template>
        </van-tab>
      </van-tabs>
    </div>

    <!-- <div @click="$router.push('/recording')" class="noticeclass noflex">
      <div class="invite">
        <div>最新邀请</div>
        <div class="moremsg">
          <div>更多</div>
          <div class="quan"><van-icon name="arrow" /></div>
        </div>
      </div>
      <div>
        <div class="invitemsg">
          <div></div>
          <div>您成功邀请{{ sub.company_name }}</div>
        </div>
        <div class="timeclass">
          <img id="timeimg" src="../../assets/time.png" />
          <div>{{ sub.add_time }}</div>
        </div>
      </div>
    </div> -->
    <!-- <div @click="$router.push('systeminformation')" class="noticeclass noflex">
      <div class="invite">
        <div>渠道优势</div>
        <div>更多</div>
      </div>
      <div>
        <div class="invitemsg">
          <div class="nodian"></div>
          <div>上海某某有限公司已注册成功，您已获10元</div>
        </div>
        <div class="timeclass">
          <img id="timeimg" src="../../assets/time.png" />
          <div>2020-11-09 16:00</div>
        </div>
      </div>
      <div>
        <div class="invitemsg">
          <div class="nodian"></div>
          <div>您的好友马冬梅已注册成功，您已获10元</div>
        </div>
        <div class="timeclass">
          <img id="timeimg" src="../../assets/time.png" />
          <div>2020-11-09 16:00</div>
        </div>
      </div>
    </div> -->

    <!-- <div @click="$router.push('/incomerecording')" class="noticeclass noflex">
      <div class="invite">
        <div>最近收益</div>
        <div class="moremsg">
          <div>更多</div>
          <div class="quan"><van-icon name="arrow" /></div>
        </div>
      </div>
      <div>
        <div class="invitemsg">
          <div></div>
          <div>{{ recharge.company_name }}</div>
        </div>
        <div class="timeclass">
          <img id="timeimg" src="../../assets/time.png" />
          <div>{{ recharge.add_time }}</div>
        </div>
      </div>
    </div> -->
    <!-- <bottomes class="bt" :msg="bomsg"></bottomes> -->
    <van-popup v-model="show">
      <div class="alertclass">
        <div class="imgdivclass"><img src="../../assets/people.png" /></div>
        <p>请申请成为业务合伙人</p>
        <div class="buab">
          <div @click="confirm">去申请</div>
          <div @click="cancle">取消</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
import PubSub from "pubsub-js";
export default {
  components: {},
  data() {
    return {
      bomsg: "我要申请成为合伙人",
      show: false,
      phone: localStorage.getItem("phone"),
      top1msg: "", //系统消息
      sub: {
        company_name: "",
      }, //最新邀请
      recharge: {
        company_name: "",
      }, //渠道收益
      userInfo: {
        company_name: "",
        account: "",
      }, //用户信息
      SwiperImg: [require("../../assets/home1.png")],
      okren: false, //是否已认证
      active: "0",
    };
  },
  mounted() {
    //查询首页所有信息
    PubSub.subscribe("okren", () => {
      this.okren = true; //已认证
    });
    this.selectallmsg();
  },
  methods: {
    /**
     * 点击更多
     */
    tabchange(msg){
       
      if(msg=="3"){
        this.$router.push("/recording")
      }
    },
    /**
     * 查询首页信息的值
     */
    selectallmsg() {
      this.$http
        .post("/firm/v1/Channel/channel_index", {
          reqType: "userinfo",
        })
        .then((res) => {
           
          let allmsg = JSON.parse(res.data);
          this.top1msg = allmsg.data.message;
          if (allmsg.data.sub != null) {
            this.sub = allmsg.data.sub;
          }

          this.recharge = allmsg.data.recharge;
          this.userInfo = allmsg.data.userInfo;
        });
    },

    confirm() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
       
      this.$http
        .post("/firm/v1/Channel/apply_channel", {
          reqType: "userinfo",
        })
        .then((res) => {
          Toast("已提交");
          this.show = false;
           
        });
    },
    cancle() {
       
      this.show = false;
    },
    clickmeth() {
      this.show = true;
    },
  },
};
</script>
<style scoped>
#imgid{
  min-width: 1.62rem;
}
.moremsgclass{
  display: flex;
  align-items: center;
}
.withdrawclassthe span {
  color: #ff4f00;
}
.withdrawclassthe {
  font-size: 0.3rem;
  line-height: 0.3rem;
  color: #8b8b8b;
}
.montimeclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.13rem;
}
.zccla {
  padding: 0.35rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #d7d7d7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #d7d7d7;
}
.timeandmsgclass img {
  width: 0.19rem;
  height: 0.19rem;
  margin-right: 0.18rem;
}
.timeandmsgclass {
  font-size: 0.24rem;
  line-height: 0.3rem;
  color: #929292;
  display: flex;
  align-items: center;
  margin-top: 0.15rem;
}
.zcclaceclass {
  font-size: 0.28rem;
  line-height: 0.3rem;
  color: #333333;
  font-weight: 500;
}
.yaoclass {
  font-size: 0.28rem;
  line-height: 0.3rem;
}
.actclass {
  margin: 0.44rem 0.42rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}
.allmsgclass2 {
  background-color: #ffffff;
  min-height: 100%;
}

.contact p {
  margin-top: 0;
  margin-bottom: 0;
}
#pclass {
  margin-bottom: 0.13rem;
}
.renimgclass {
  display: flex;
  align-items: center;
}
.quan {
  border: 1px solid #9f9f9f;
  border-radius: 50%;
  height: 0.3rem;
  margin-left: 0.1rem;
}
.moremsg {
  display: flex;
  align-items: center;
}
.banner >>> .van-swipe {
  height: 2rem;
  margin: 0.2rem 0.32rem;
}
.imgclass {
  height: 100%;
  width: 100%;
}

.imgdivclass {
  text-align: center;
  margin-top: 0.54rem;
}

.buab > div {
  width: 50%;
  text-align: center;
  padding: 0.26rem 0;
}
.buab > div:first-child {
  background-color: #f65a16;
  color: #ffffff;
}
.buab > div:nth-child(2) {
  background-color: #dcdcdc;
}
.buab {
  position: absolute;
  bottom: 0;
  font-size: 0.32rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.alertclass p {
  font-size: 0.34rem;
  text-align: center;
}
.van-popup--center {
  border-radius: 0.2rem;
}
.alertclass {
  width: 5.44rem;
  height: 3.88rem;
  overflow: hidden;
  position: relative;
}
.alertclass img {
  width: 1.2rem;
  height: 1.2rem;
}
.bt {
  margin: 0.5rem 0;
}
.topmsg {
  background-image: url("../../assets/homeback.png");
  background-size: 100% 100%;
}

.timeclass {
  display: flex;
  align-items: center;
  color: #929292;
  font-size: 0.24rem;
}

.invite {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.16rem;
  border-bottom-color: #616b80;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #f7773e;
}
.invite > div:nth-child(2) {
  color: #9f9f9f;
}
#timeimg {
  width: 0.21rem;
  height: 0.21rem;
  margin-right: 0.1rem;
}
.invitemsg {
  display: flex;
  align-items: center;
  margin-top: 0.33rem;
  margin-bottom: 0.1rem;
}
.nodian {
  display: none;
}
.noticeclass {
  font-size: 0.28rem;
  line-height: 0.4rem;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  margin: 0.25rem 0.32rem;
  padding: 0.24rem 0.34rem;
  border-radius: 0.2rem;
}
.noflex {
  display: block;
  font-size: 0.26rem;
}
.noflex > div:first {
  font-size: 0.28rem;
  color: #f7773e;
  line-height: 0.4rem;
}
.noticeclass .img1 {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.21rem;
}
.daoclass {
  color: #ffffff;
  background-color: #ffb695;
  font-size: 0.28rem;
  line-height: 0.4rem;
  padding: 0.18rem 0.34rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  margin-top: 0.34rem;
}
.themsg {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.setup {
  display: flex;
  justify-content: space-between;
  margin: 0 0.33rem;
  padding-top: 0.1rem;
}
.imgdiv {
  padding: 0.1rem;
  background-color: #ffffff;
}
.setupclass {
  width: 0.39rem;
  height: 0.39rem;
}
.mybodyMsg {
  padding: 0.45rem 0.32rem;
  padding-bottom: 0;
  color: #ffffff;
}
.imageContact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imgdiv {
  width: 1.36rem;
  height: 1.36rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.19rem;
}
.touimg {
  width: 100%;
  height: 100%;
}
.imageContact + p {
  margin-top: 0.31rem;
  margin-bottom: 0.16rem;
  font-size: 0.26rem;
  font-weight: bold;
}
.imageContact .qrclss img {
  width: 0.7rem;
  height: 0.7rem;
}

.contact p:first-child {
  font-weight: bold;
  font-size: 0.3rem;
}
.contact p:nth-child(2) {
  font-size: 0.3rem;
}
.imageContact .renimgclass img {
  width: 0.9rem;
  margin-left: 0.18rem;
}
.topmsgcalssthe {
  margin-top: -0.5rem;
  box-shadow: 0px 2px 6px 0px rgba(156, 156, 156, 0.23);
}
</style>